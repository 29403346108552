/* Style for the full-screen overlay */
.world2-overlay {
  position: absolute; /* Position it relative to ContainerBackground */
  top: 0;
  left: 0;
  width: 100vw; /* Cover the full width */
  height: 100vh; /* Cover the full height */
  background-color: rgba(
    111,
    30,
    30,
    0.3
  ); /* Semi-transparent overlay, brown */
  z-index: 1; /* Ensures it's above the background but below content */
}

.world2-container {
  position: relative;
  z-index: 2; /* Ensure content is above the overlay */
}

.next-button-container {
  position: absolute; /* Absolute position for the button container */
  bottom: 20px; /* Adjust based on your design */
  right: 20px; /* Adjust based on your design */
  display: flex;
  justify-content: flex-end;
}

.previous-button-container {
  position: absolute; /* Absolute position for the button container */
  bottom: 20px; /* Adjust based on your design */
  left: 20px; /* Adjust based on your design */
  display: flex;
  justify-content: flex-end;
}

.toggle-font-size {
  position: absolute; /* Absolute position for the button container */
  bottom: 20px; /* Adjust based on your design */
  right: 100px; /* Adjust based on your design */
  display: flex;
  justify-content: flex-end;
}

.back-to-map-btn {
  position: absolute;
  z-index: 10; /* Ensure it's above other elements */
  margin: 10px;
}

.accessibility-menu {
  z-index: 10;
  margin: 10px;
}
.finn-container {
  position: relative;
}

.finn-character {
  position: absolute;
  left: -100px;
  top: 0;
  z-index: 4;
  height: 100vh; /* Makes the image fit the height of the screen */
  object-fit: cover; /* Ensures the image covers the area without distorting its aspect ratio */
}

.finn-speech-bubble {
  position: fixed;
  bottom: 0;
  left: 50%; /* Center the bubble */
  transform: translateX(-50%); /* Adjust positioning to center */
  width: calc(65% - 20px); /* Full width with some padding */
  min-height: 25vh;
  margin: 0 auto;
  background-color: #f9f9f9;
  border-radius: 8px 8px 0 0; /* Rounded corners at the top */
  padding: 15px;
  border: 2px solid #000;
  font-family: "Arial", sans-serif;
  font-size: 16px;
  box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1); /* Optional: Adds a slight shadow for depth */
  z-index: 5;
}
/*Finn appear*/
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.finn-slide-in {
  animation: slideInFromLeft 1s ease-out forwards;
}
/* Text appear*/
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.finn-speech-bubble {
  animation: fadeIn 1s ease-out forwards;
}
/*Text pointer or caret*/
/* Add this to World2.css */
@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.cursor {
  display: inline-block;
  vertical-align: text-bottom;
  width: 8px; /* Adjust for desired "fat" width */
  height: 1em; /* Match line height of your text */
  background-color: black; /* Cursor color */
  margin-left: 2px; /* Space between text and cursor */
  animation: blink 0.5s step-end infinite;
}
.graphic-container {
  display: flex;
  justify-content: center; /* Center images horizontally */
  align-items: center;
  flex-wrap: wrap; /* Allow items to wrap as needed */
  gap: 10px; /* Optional: adds space between images */
  margin-top: 5vh;
}

.graphic {
  display: block;
  max-width: 30%; /* Adjust based on your needs and number of images */
  height: auto; /* Maintains the aspect ratio of the image */
  position: relative; /* Position relative to its normal position */
  z-index: 4;
}
