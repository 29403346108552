/* WorldStart.css */
.world-container {
    opacity: 0; /* Initial state for animation */
    transition: opacity 0.5s ease-in-out;
    margin: 40px 30px;
  }
  
  .world-header {
    text-align: center;
    margin-bottom: 20px;
    color: #2D3748; /* Dark gray */
  }
  
  .world-description {
    margin: 15px 0;
    color: #4A5568; /* Slightly lighter gray */
    font-size: 1.1rem;
  }
  
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
  }
  
  .col-4, .col-8 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .text-bubble {
    background-color: #E2E8F0; /* Light gray-blue */
    padding: 20px;
    border-radius: 25px;
    margin-bottom: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    font-size: 1rem;
    color: #2D3748;
  }

  .button-container {
    margin-top: 20px; /* Adds some space above the buttons */
    gap: 20px; /* Adds space between the buttons */
  }

  .button-container button {
    background-color: #319795; /* Teal */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .button-container button:hover {
    background-color: #2C7A7B; /* Darker teal */
  }

.world-container h1 {
  font-size: 2.5rem; /* Increase the font size */
  color: #2c5282; /* Use a theme color, darker blue */
  font-weight: bold; /* Make the header bold */
  margin-bottom: 0.8rem; /* Increase bottom margin for better spacing */
}

.world-container .overview {
  font-size: 1.25rem;
  font-weight: medium; /* Medium weight for detailed text */
  color: #4a5568; /* Slightly vibrant blue to highlight important information */
  padding: 0 1rem; /* Padding for better text alignment */
}
