.boxContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pinkCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #F6EBEB;
  padding: 0px 20px 20px 20px;
  width: calc(100% - 40px); /* Adjust width as needed, accounting for padding */
  margin: -20px 20px 60px 20px;
  box-sizing: border-box;
  position: relative; /* For positioning relative to its normal position */
  z-index: 1; /* Ensure it's above the background but below interactive elements */
  max-height: 70vh;
  border-radius: 0 0 20px 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1); /* Add a subtle shadow for depth */
}

.blueCard {
  background-color: #C5EEF2; /* A blue color; adjust as needed */
  justify-content: center;
  width: calc(100% - 160px); /* Slightly narrower than pinkCard */
  max-width: 1308px; /* Maximum width for the card */
  box-sizing: border-box;
  position: relative; /* For positioning relative to its normal position */
  z-index: 2; /* Ensure it's above pinkCard */
  min-height: 75vh; /* Adjust height as needed */
  border-radius: 0 0 20px 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1); /* Add a subtle shadow for depth */
  overflow: auto;
}

@media (max-width: 768px) {
  .pinkCard {
    margin: -20px 0px 20px 0px; /* Adjust margin for smaller screens */
    width: calc(100% - 20px); /* Adjust width for smaller screens */
  }
  .blueCard {
    margin: 20px 0px 0px 0px; /* Adjust margin for smaller screens */
    width: calc(100% - 20px); /* Adjust width for smaller screens */
  }
  .themeDropDownMenu{
    top:10em;
  }
}

@media(max-height:660px){
  .pinkCard{
    max-height: 60vh;
  }
  .blueCard{
    min-height: 65vh;
}
}



/* Additional styles to ensure layout works as expected */
.container-background {
  position: relative; /* Ensure background container allows for proper stacking */
}

.themeIcon{
  padding-right: 10px;
}


.panicButton {
  position: fixed; /* Fixed position relative to the viewport */
  right: 5em; /* 10px from the right edge of the viewport */
  bottom: 2em; /* 10px from the bottom edge of the viewport */
  max-width: 300px; /* Maximum width of the button */
  
  z-index: 100; /* High z-index to ensure it's on top of other elements */
  

  cursor: pointer;
  
}
.panicButton:hover {
  /* Slightly increase size to indicate interactivity */
  transform: scale(1.2);
  
}
img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}
.themeDropDownMenu {
  position: fixed; /* Fixed positioning to keep it within the container */
  bottom: 2em;
  left: 20px; /* Adjust this value to position the button from the left */
  z-index: 2; /* Ensure it's above other content */
}

