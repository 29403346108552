.row > .col-5 {
    padding-left: 0px;
}
/* Style for the overall background or container in which the blue box sits */
.login-container {
    display: flex;
    flex-direction: column;
    width: 100%; /* Take full width to center content */
    max-width: 600px; /* Maximum width of the login container */
    padding-left: 0px;

}
/* styles.css */
.go-back-btn {
    position: absolute;
    left: 0;
    top: 0;
    padding: 2em;
  }
  
  .go-back-btn button {
    padding: 5px 10px;
    font-size: 12px; /* Smaller text */
  }
  
.logo{
    display: flex;
    margin: auto;
    justify-content: center;
}
.logo-text {
    padding-top: 25px;
    
}

/* Style for the blue box specifically */
.loginBox {
    width: 80%;
    min-height: 80%;
    height: auto;
    margin: 0 auto auto auto;
    padding: 5em; /* Adjust padding to match your design */
    border-radius: 8px; /* This gives the rounded corners */
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.login-text{
    margin: 20px 0px 60px 0px;
}
.login-form{
    margin: 40px 0px 100px 0px;
}
.centered-input {
    width: 100%;
    max-width: 350px; /* Adjust the max-width as needed */
    margin: auto; /* This centers the input within the blueBox */
}
.centered-input > Input {
    background-color: #FFF;
    border: #000 1px solid;
}
.centered-content {
    display: flex; /* Use Flexbox */
    flex-direction: column; /* Stack items vertically */
    justify-content: center; /* Center items vertically in the container */
    align-items: center; /* Center items horizontally in the container */
    height: 80vh; /* Full height of the viewport */
    text-align: center; /* Center text */
}
