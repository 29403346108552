.activity-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.diagram-container {
  position: relative;
  width: 60%;  /* Adjust the width as needed */
  margin: auto; /* Centers the container horizontally */
  margin-bottom: 10px;
  box-shadow: 0px 4px 8px rgba(0,0,0,0.1); /* Optional: Adds shadow for depth */
  border-radius: 8px; /* Optional: Rounds the corners */
  overflow: hidden; /* Ensures no overflow from the container */
}

.interactive-image {
  width: 100%; /* Ensures the image fills the container */
  height: auto; /* Maintains the aspect ratio of the image */
  display: block; /* Removes extra space below the image */
}

.image-slider {
  display: flex; /* Establishes a flex container */
  justify-content: center; /* Centers the children (image and buttons) horizontally */
  align-items: center; /* Aligns the children vertically in the middle */
  gap: 10px; /* Adds space between the buttons and the image */
  width: 100%; /* Ensures the slider occupies the full width of its parent */
}

.slider-btn {
  border: 1px solid black; /* border */
  background-color: #eee; /* Light grey background */
  padding: 10px 20px; /* Padding inside the button */
  cursor: pointer; /* Indicates that the element is clickable */
  border-radius: 5px; /* Rounds the corners of the button */
  font-size: 16px; /* Increases the font size for better readability */
}

.slider-btn:hover {
  background-color: #ccc; /* Changes background on hover for visual feedback */
}
