.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 75vh;
  text-align: center;
  position: relative;
  box-sizing: border-box;
}

.home-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: inherit;
}

.home-graphic {
  max-width: 40%;
  height: auto;
  margin: 0 auto;
}

.home-title {
  max-width: 80%;
  font-size: 2.7vw;
}

.home-subtitle {
  max-width: 90%;
  font-size: 1.4vw;
}

@media (max-width: 768px) {
  .home-graphic {
    max-width: 100%;
  }
  .home-container {
    padding: 10px;
  }
  .home-title {
    font-size: 5vw;
  }
  .home-subtitle {
    font-size: 2.5vw;
  }
}

@media (max-width: 480px) {
  .home-graphic {
    max-width: 100%;
  }
  .home-container {
    padding: 5px;
  }
  .home-title {
    font-size: 6vw;
  }
  .home-subtitle {
    font-size: 3vw;
  }
}


 