.map-container {
  position: relative;
  margin: 0 auto;
  width: 100%; /* Ensure the container takes up the full width of its parent */
 
}

.image-container {
  position: relative;
  display: flex; /* Use flexbox for centering */
  justify-content: center; /* Center children horizontally */
  align-items: center; /* Center children vertically */
  width: 100%;
  height: auto; /* Adjust this as necessary, possibly to a fixed height or min-height for vertical centering */
}

/* Style for the map image itself, ensuring it doesn't exceed the container's bounds */
.image-container img {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  
}

.hover-text-box {
  max-width: 250px;
  background-color: white;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 4px;
  display: inline-block;
  z-index: 100;
  pointer-events: none; /* Ensure the box doesn't interfere with mouse events */
}

.focused-area{
  outline: 2px solid #000;
  outline-offset: 5px;
}

.map-overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  pointer-events: none; /* Ensure the overlay doesn't interfere with mouse events */;
}

