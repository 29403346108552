*{
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 0.7px;
}

/*.container {
  background-color: antiquewhite;
  width: 100vw;
  min-height: 100vh;
}*/
html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; 
}
