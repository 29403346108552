.endScreenContainer {
    width: 100vw;        /* Full width of the viewport */
    height: 100vh;       /* Full height of the viewport */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Align children vertically center */
    align-items: center;     /* Align children horizontally center */
    position: fixed;
    top: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(10px);  /* Blur effect */
    z-index: 4;
}

.axolotl-container, .character-container, .to-map-button-container {
    display: flex;
    justify-content: center; /* Center content horizontally */
    align-items: center;     /* Center content vertically */
    margin: 10px 0;          /* Adds vertical spacing between rows */
    margin-bottom: 0px;
}

.rio-img{
    transform: scaleX(-1); /* Adjust vertical alignment and flip horizontally */
}

.row {
    flex-wrap: nowrap;       /* Prevents the flex items from wrapping */
}

.col-6 {
    display: flex;
    justify-content: center; /* Center content inside columns */
    align-items: center;
}

.axolotl-container img, .character-container img, .to-map-button-container img{
    width: 100px;            /* Width of images */
    height: auto; 
}
.to-map-button-container .col-6 {
    flex-grow: 1;            /* Allows button columns to grow and fill space */
}
.to-map-button-container{
    min-width: 750px;
}
