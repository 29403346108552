/* ContainerBackground.css */

.container-background {
    position: absolute; 
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    background-size: cover;
    background-position: center;
}

.container-content {
    position: relative; /* Ensure content is positioned relative to your background container */
    z-index: 1;/* Higher z-index to make it sit above the background */
    padding: 20px;
    margin-bottom: 0;
}

/* Ensure the body and html elements take up the full height of the viewport */
html, body {
    height: 100vh;
    margin: 0;
    padding: 0;
}

